import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "../components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "../components/imageDarkTextLeftWithButton"
import CTABusiness from "../../../general/ctaBusiness"
import FAQList from '../../../general/faqsList';
import InnerPageHeader from "../components/innerPageHeader"
import { scrollToElement } from "../../../../../utility/utils"
import DarkBelowFoldCards from "../../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../../general/more-for-you";

// hero image
import EntryIllustration from '../../../../../../assets/img/bill-hero-image.svg'
import EntryIllustrationBlur from "../../../../../../assets/img/business/blur/bill-payments-blur.inline.svg";

import SwitchIcon from "../../../../../../assets/img/kuda-switch-green.inline.svg"
import MeterIcon from "../../../../../../assets/img/kuda-meter.inline.svg"
import InternetIcon from '../../../../../../assets/img/kuda-globe.inline.svg'

import bill_PaymentsIllustration from "../../../../../../assets/img/business/payments/bill_payment-illustration.svg"
import subPackageIllustration from "../../../../../../assets/img/business/payments/subPackage-illustration.svg"
import tokensByEmailIllustration from "../../../../../../assets/img/business/payments/tokensByEmail-illustration.svg"

import KudaPOSTerminalIcon from "../../../../../../assets/img/more-for-you/kuda-pos-icon.inline.svg"
import InvoicingIcon from "../../../../../../assets/img/more-for-you/kuda-invoicing.inline.svg"
import VirtualPOSIcon from '../../../../../../assets/img/more-for-you/kuda-virtual-icon.inline.svg'

import { Img } from "react-image";


const entryContent = {
    title: (<span>
        <span className="color-secondary">Pay</span> your business bills any time on Kuda Business.
    </span>),
    subtitle: "Find all major internet, TV, electricity, transport and betting service providers on the Kuda Business web and mobile apps.",
    name: "Download Kuda Business",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
    {
        icon: <SwitchIcon />,
        text: "Pay for business utilities without switching apps."
    },
    {
        icon: <MeterIcon />,
        text: "Get your meter, internet and TV tokens on the app."
    },
    {
        icon: <InternetIcon />,
        text: "Find all popular internet and TV subscription packages."
    },
]

const bill_Payments = {
    title: "Make easy bill payments any time.",
    subtitle: "All major bills and service providers are on Kuda Business, so you’ll never need to switch to another app to pay for your business utilities.",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
    name: "Join Kuda Business",
    illustration: (
        <Img src={bill_PaymentsIllustration} className="kuda-business-image" />

    ),
}

const tokensByEmail = {
    title: "Get your tokens on the app and by email.",
    subtitle: "Kuda Business makes it easy for you to find your meter, internet and TV tokens without checking your inbox.",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
    name: "Download Kuda Business",
    illustration: (

        <Img src={tokensByEmailIllustration} className="kuda-business-image" />

    ),
}

const subPackage = {
    title: "Find all popular internet and TV subscription packages.",
    subtitle: "Whether you have a favourite internet or TV subscription package or you’re looking for the right one for you, you’ll find it on the Kuda Business app.",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
    name: "Open a Kuda Business Account",
    illustration: (

        <Img src={subPackageIllustration} className="kuda-business-image" />

    ),
}


const questions = [

    {
        heading: "What utilities and bills can I pay on Kuda Business?",
        list: (
            <span className="flex flex-column  mt-2">

                <span className="faq-description f-16">You can make internet, TV, electricity, transport and betting payments on Kuda Business.</span>
            </span>),

        index: 1
    },
    {
        heading: "How do I make bill payments on Kuda Business?",
        list: (

            <span className="flex flex-column  mt-2">
                <span className="faq-description f-16">To make bill payments on Kuda Business, follow the steps below: </span>

                <span className="pt-3 career-message-margin-bottom ">
                    <div className="flex flex-column  leading-md-6 f-md-16">
                        <div className="mb-3">1. Sign in to the Kuda Business web or mobile app, then tap  <b>Payments.</b></div>
                        <div className="mb-3">2. Tap <b>Pay A Bill.</b></div>
                        <div className="mb-3">3. Tap the bill you’d like to pay and follow the prompts on your screen to make the bill payment.</div>

                    </div>
                </span>

            </span>),
        index: 2
    },

]

const moreForYou = [
    {
      icon: <KudaPOSTerminalIcon />,
      title: "POS",
      subText: `Buy a physical POS, lease it for a 
      one-time fee, or get a virtual POS to accept payments quickly.`,
      linkTo: `/en-ng/business/pos/`
    },
  
    {
      icon: <InvoicingIcon />,
      title: "Invoicing",
      subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
      linkTo: `/en-ng/business/invoicing/`
    },
    {
      icon: <VirtualPOSIcon />,
      title: "Virtual POS",
      subText: `Empower your salespeople to accept payments instantly at all your business locations.`,
      linkTo: `/en-ng/business/virtual-pos/`
    },
  
  ]
const BillPayments = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (

        <Fragment>

            <InnerPageHeader
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                buttonName={entryContent.name}
                buttonUrl={entryContent.url}
                entryIllustration={EntryIllustration}
                fallback={<EntryIllustrationBlur/>}
            />
            <DarkBelowFoldCards topFeatures={businessTopFeatures} />
            <ImageDarkTextRightWithButton
                title={bill_Payments.title}
                subtitle={bill_Payments.subtitle}
                illustration={bill_Payments.illustration}
                name={bill_Payments.name}
                url={bill_Payments.url}
                isExternal={true}
            />
            <ImageDarkTextLeftWithButton
                title={tokensByEmail.title}
                subtitle={tokensByEmail.subtitle}
                illustration={tokensByEmail.illustration}
                name={tokensByEmail.name}
                url={tokensByEmail.url}
                isExternal={true}
            />
            <ImageDarkTextRightWithButton
                title={subPackage.title}
                subtitle={subPackage.subtitle}
                illustration={subPackage.illustration}
                name={subPackage.name}
                url={subPackage.url}
                isExternal={true}
            />

            <FAQList title={"Bill Payments FAQs"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou} />
            <CTABusiness />
        </Fragment>
    )
}

export default BillPayments

